import { t } from "i18next";
import React, { useState } from "react";
import { GameProviderRequest } from "../../../common/types/Casino/GameProvider";

import "./SelectProviderPopap.scss";

interface ISelectProviderPopap {
  setProviderPopapOpen: React.Dispatch<React.SetStateAction<boolean>>;
  providersList: GameProviderRequest | undefined;
  setSelectedProviders: React.Dispatch<React.SetStateAction<number[]>>;
  selectedProviders: number[];
  setShowByParams: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectProviderPopap = ({
  setProviderPopapOpen,
  providersList,
  setSelectedProviders,
  selectedProviders,
  setShowByParams,
}: ISelectProviderPopap) => {
  const [selectedProviderPrev, setSelectedProviderPrev] =
    useState<number[]>(selectedProviders);

  const handleClosePopap = () => {
    setProviderPopapOpen(false);
  };

  const handleApply = () => {
    setSelectedProviders(selectedProviderPrev);
    setProviderPopapOpen(false);
    setShowByParams(true);
  };

  const handleSelectItem = (item: number) => {
    setSelectedProviderPrev((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((existingId: number) => existingId !== item);
      } else {
        return [...prevState, item];
      }
    });
  };
  return (
    <div className="popup-holder-bc full-screen   ">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc">
          <div className="casino-category-providers opened">
            <div className="casino-category-providers-header">
              <h3 className="casino-category-providers-heading">
                {t("casino.Providers")}
              </h3>
              <button
                className="providers-close-button-bc bc-i-close-remove "
                type="button"
                onClick={handleClosePopap}
              />
            </div>
            <div className="casino-category-providers-select">
              <div className="casino-category-provider-count-bc">
                {selectedProviderPrev.length === 0
                  ? "No providers selected"
                  : `${selectedProviderPrev.length} SELECTED`}
              </div>
              <div className="sport-search-bc ">
                <div className="ss-icon-holder-bc">
                  <i className="ss-icon-bc bc-i-search" />
                </div>
              </div>
            </div>
            <div
              className="casino-providers-list"
              data-scroll-lock-scrollable=""
            >
              {providersList
                ? Object.keys(providersList).map((item) => {
                    return providersList[item]?.map((item) => (
                      <div
                        className={`casino-providers-list-item ${
                          selectedProviderPrev.includes(item.id)
                            ? "selected"
                            : ""
                        }`}
                        data-badge=""
                        key={item.id}
                        onClick={() => handleSelectItem(item.id)}
                      >
                        {item.name}
                        <span className="checked-icon bc-i-checked" />
                      </div>
                    ));
                  })
                : null}
            </div>
            <div className="save-filter-button-bc">
              <button
                className="btn a-color "
                type="button"
                title="Apply"
                onClick={handleApply}
              >
                <span>{t("admin.Apply")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SelectProviderPopap };
