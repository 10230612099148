const mockCarusel: { img: string; name: string }[] = [
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_4ab33c2e16ab4982a4231ec2dbde7a84.webp",
    name: "We Transfer",
  },
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_029c62060e47fbcd4c4643891b5cf139.webp",
    name: "Aviatör",
  },
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_46d0fa018bab2d973df02f415e3c419b.webp",
    name: "Yat",
  },
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_292a0a3a65da650e65fee5c69fe2f6af.webp",
    name: "Wazdan",
  },
];

export { mockCarusel };
