import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Main } from "../pages/Main/Main";
import { setAuth } from "../store/action/auth/setauth";
import { useDispatch } from "react-redux";
import { RouteWrapper } from "./RoutWrapper";
import { AdminPanel, CasinoInfo } from "../services";
import { setUserInfo } from "../store/action/auth/setuserinfo";
import { setUserBalance } from "../store/action/auth/setuserbalance";
import { Casino } from "../pages/Casino/Casino";
import { Promotions } from "../pages/Promotions/Promotions";
import { Aviator } from "../pages/Aviator/Aviator";
import { Zeplin } from "../pages/Zeplin/Zeplin";
import { SpaceMan } from "../pages/SpaceMan/SpaceMan";
import { MaltbahisCarck } from "../pages/MaltbahisCarck/MaltbahisCarck";
import { Jackpot } from "../pages/Jackpot/Jackpot";
import { Tournaments } from "../pages/Tournaments/Tournaments";
import { Games } from "../pages/Games/Games";
import { LiveCasino } from "../pages/LiveCasino/LiveCasino";
import { CanliTakvim } from "../pages/CanliTakvim/CanliTakvim";
import { BahisTurnament } from "../pages/BahisTurnament/BahisTurnament";
import { useTypeSelector } from "../hooks/useTypeSelector";
import { Live } from "../pages/Live/Live";
import { Sport } from "../pages/Sport/Sport";
// import loadGit from "./accets/images/gif/loader/loader.gif";
import loadGit from "../accets/images/gif/loader/loader.gif";
import { setUserInit } from "../store/action/auth/setuserinit";
import { setFavoritsUser } from "../store/action/favorits/setFavoritsUser";
import { LiveCalendar } from "../pages/LiveCalendar/LiveCalendar";
import { TournamentsSports } from "../pages/TournamentsSports/TournamentsSports";
import { Betconstruct } from "../pages/Betconstruct/Betconstruct";
import { EdgeGaming } from "../pages/EdgeGaming/EdgeGaming";
import { LeapGaming } from "../pages/LeapGaming/LeapGaming";
import { Kiron } from "../pages/Kiron/Kiron";

const NotFoundComponent = () => {
  const language = useTypeSelector((data) => data.settingsReducer.language);
  return <Navigate to={`/${language.languageShort}`} />;
};

const Navigation = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  async function getFavoritsGames() {
    const res = await CasinoInfo.favoritGames();
    dispatch(setFavoritsUser(res.data));
  }

  async function userBalance() {
    try {
      const resbalance = await AdminPanel.userBalance();
      dispatch(setUserBalance(resbalance.data));
      dispatch(setAuth("player"));
      getFavoritsGames();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error(error);
      localStorage.removeItem("token");
    }
  }
  async function userInfo() {
    try {
      const res = await AdminPanel.userInfo();
      dispatch(setUserInfo(res.data));
    } catch (error) {
      localStorage.removeItem("token");
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isAuthenticated = !!token;
    if (isAuthenticated) {
      userBalance();
      userInfo();
    } else {
      dispatch(setUserInit());
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);
  return (
    <>
      {loading ? (
        <div className="layout-bc">
          <div className="bc-loader medium ">
            <div className="bc-loader-contain">
              <img src={loadGit} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <Router>
          <Routes>
            <Route
              path={"/:en/sports/pre-match/event-view"}
              element={<RouteWrapper element={<Sport />} />}
            />
            <Route
              path={"/:en/sports/live/event-view"}
              element={<RouteWrapper element={<Live />} />}
            />
            <Route
              path={"/:en"}
              element={<RouteWrapper element={<Main />} />}
            />
            <Route
              path={"/:en/casino/jackpot"}
              element={<RouteWrapper element={<Jackpot />} />}
            />
            <Route
              path={"/:en/wonderwheel"}
              element={<RouteWrapper element={<MaltbahisCarck />} />}
            />
            <Route
              path={"/:en/casino/tournaments"}
              element={<RouteWrapper element={<Tournaments />} />}
            />
            <Route
              path={"/:en/promotions"}
              element={<RouteWrapper element={<Promotions />} />}
            />
            <Route
              path={"/:en/casino/game-view/806666"}
              element={<RouteWrapper element={<Aviator />} />}
            />
            <Route
              path={"/:en/casino/game-view/9010"}
              element={<RouteWrapper element={<Zeplin />} />}
            />
            <Route
              path={"/:en/casino/game-view/400043028"}
              element={<RouteWrapper element={<SpaceMan />} />}
            />
            <Route
              path={"/:en/live-casino/home/-1/All"}
              element={<RouteWrapper element={<LiveCasino />} />}
            />
            <Route
              path={"*"}
              element={<RouteWrapper element={<NotFoundComponent />} />}
            />
            <Route
              path={"/:en/casino/slots/-1"}
              element={<RouteWrapper element={<Casino />} />}
            />
            <Route
              path={"/:en/games"}
              element={<RouteWrapper element={<Games />} />}
            />
            <Route
              path={"/:en/sports/live-calendar"}
              element={<RouteWrapper element={<CanliTakvim />} />}
            />
            <Route
              path={"/:en/sports/tournaments"}
              element={<RouteWrapper element={<BahisTurnament />} />}
            />
            <Route
              path={"/:en/sports/pre-match/live-calendar"}
              element={<RouteWrapper element={<LiveCalendar />} />}
            />
            <Route
              path={"/:en/sports/pre-match/tournaments"}
              element={<RouteWrapper element={<TournamentsSports />} />}
            />
            <Route
              path={"/:en/virtual-sports/betconstruct"}
              element={<RouteWrapper element={<Betconstruct />} />}
            />
            <Route
              path={"/:en/virtual-sports/ext/game/155000"}
              element={<RouteWrapper element={<EdgeGaming />} />}
            />
            <Route
              path={"/:en/virtual-sports/ext/game/4000235"}
              element={<RouteWrapper element={<LeapGaming />} />}
            />
            <Route
              path={"/:en/virtual-sports/ext/game/40004001"}
              element={<RouteWrapper element={<Kiron />} />}
            />
          </Routes>
        </Router>
      )}
    </>
  );
};

export { Navigation };
