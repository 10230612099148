import React, { useEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "../../common/types/Casino/CasinoIconGameType";
import { GameProviderRequest } from "../../common/types/Casino/GameProvider";
import { Carusel } from "../../components/carusel/Carusel";
import { CasinoList } from "../../components/casinoList/CasinoList";
import { Layout } from "../../components/layout";
import { LiveCasinoProviders } from "../../components/livecasino/LiveCasinoProviders/LiveCasinoProviders";
import { LiveCasinoSearch } from "../../components/livecasino/LiveCasinoSearch/LiveCasinoSearch";
import { CasinoInfo } from "../../services";
import { mockCarusel } from "../Main/mockCarusel";

import "./LiveCasino.scss";

const LiveCasino = () => {
  const [load, setLoad] = useState(true);

  const [providersList, setProvidersList] = useState<GameProviderRequest>();
  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
  const [selectedProvidersUrl, setSelectedProvidersUrl] = useState("");

  const [loadingGamesAdd, setLoadingGamesAdd] = useState(false);
  const [loadStop, setLoadStop] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(true);

  const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
  const [checkedGame, setCheckedGame] = useState(-1);
  const page = useRef(1);

  const [seacrhGames, setSeacrhGames] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    page.current = 1;
    async function getProvidersList() {
      const res = await CasinoInfo.providers(
        "?type=live-casino&is_mobile=false"
      );
      setProvidersList(res.data);
    }
    getProvidersList();
  }, []);

  useEffect(() => {
    if (selectedProviders.length > 0) {
      let timeUrl = "";
      selectedProviders.map((item: number) => {
        timeUrl = timeUrl + item + ",";
      });
      setSelectedProvidersUrl(timeUrl);
    } else {
      setSelectedProvidersUrl("");
    }
  }, [selectedProviders]);

  useEffect(() => {
    page.current = 1;
    setLoadStop(false);
    async function casinoGames() {
      setLoadingAdd(true);
      const res = await CasinoInfo.games(
        `/1?current_page=1&limit=35&page=${page.current}&is_mobile=true${
          selectedProvidersUrl.length > 0
            ? `providers_ids[]=${selectedProvidersUrl}`
            : ""
        }`
      );
      setCasinoGames(res.data.data);
      setLoadingAdd(false);
    }
    async function casinoGamesSearch() {
      setLoadingAdd(true);
      setLoadingGamesAdd(true);
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=35&page=${page.current}&search=${seacrhGames}&is_mobile=true`
      );
      setCasinoGames(res.data.data);
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
      setLoadingAdd(false);
    }
    if (seacrhGames.length === 0) {
      casinoGames();
    } else {
      casinoGamesSearch();
    }
  }, [selectedProviders, selectedProvidersUrl, seacrhGames]);

  async function loadMoreItem() {
    async function casinoGames() {
      if (!loadStop) {
        page.current++;
        setLoadingGamesAdd(true);
        const res = await CasinoInfo.games(
          `/1?current_page=1&limit=35&page=${page.current}&is_mobile=true${
            selectedProvidersUrl.length > 0
              ? `providers_ids[]=${selectedProvidersUrl}`
              : ""
          }`
        );
        setCasinoGames((prevState) => prevState.concat(res.data.data));
        if (res.data.data.length === 0) {
          setLoadStop(true);
        } else {
          setLoadStop(false);
        }
        setLoadingGamesAdd(false);
        setLoadingAdd(false);
      }
    }
    async function casinoGamesSearch() {
      page.current++;
      setLoadingGamesAdd(true);
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=35&page=${page.current}&search=${seacrhGames}&is_mobile=true`
      );
      setCasinoGames((prevState) => prevState.concat(res.data.data));
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
      setLoadingGamesAdd(false);
      setLoadingAdd(false);
    }
    if (seacrhGames.length === 0) {
      if (!loadStop) {
        casinoGames();
      }
    } else {
      if (!loadStop) {
        casinoGamesSearch();
      }
    }
  }
  return (
    <Layout
      containerStyles="is-home-page casino-slots compact-footer "
      navHeader="livecasino"
      loadStop={loadStop}
      footerNav="liveCasino"
      footerNavType="casino"
    >
      <div className="layout-content-holder-bc">
        {load ? null : <Carusel mockCarusel={mockCarusel} />}
        <LiveCasinoProviders
          selectedProviders={selectedProviders}
          setSelectedProviders={setSelectedProviders}
          providersList={providersList}
        />
        <LiveCasinoSearch
          setSeacrhGames={setSeacrhGames}
          seacrhGames={seacrhGames}
        />
        <CasinoList
          loadingAdd={loadingAdd}
          casinoGames={casinoGames}
          checkedGame={checkedGame}
          setCheckedGame={setCheckedGame}
          loadMoreItem={loadMoreItem}
          loadingGamesAdd={loadingGamesAdd}
        />
      </div>
    </Layout>
  );
};

export { LiveCasino };
