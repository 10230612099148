import React from "react";
import { useDispatch } from "react-redux";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import { setModalLogin } from "../../../store/action/modals/setmodallogin";
import { setModalPopap } from "../../../store/action/modals/setmodalpopap";
import { setModalRegister } from "../../../store/action/modals/setmodalregister";
import { AuthModal } from "./AuthModal/AuthModal";

import "./PopapMeneger.scss";
import { RegisterModal } from "./RegisterModal/RegisterModal";

const PopapMeneger = () => {
  const dispatch = useDispatch();
  const modalAuthOpen = useTypeSelector(
    (data) => data.modalsReducer.modalAuthOpen
  );
  const modalRegisterOpen = useTypeSelector(
    (data) => data.modalsReducer.modalRegisterOpen
  );
  const popapOpen = useTypeSelector((data) => data.modalsReducer.popapOpen);
  const handleClosePopap = () => {
    dispatch(setModalPopap(false));
    dispatch(setModalLogin(false));
    dispatch(setModalRegister(false));
  };
  return (
    <div
      className={`popup-holder-bc ${
        popapOpen ? "full-screen" : "windowed hidden"
      }   content-manager `}
      style={{}}
    >
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc">
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={handleClosePopap}
          />
          {modalAuthOpen ? (
            <AuthModal
              handleClosePopap={handleClosePopap}
            />
          ) : null}
          {modalRegisterOpen ? (
            <RegisterModal
              handleClosePopap={handleClosePopap}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { PopapMeneger };
