import React, { useEffect } from "react";
import { Layout } from "../../components/layout";
import Helmet from "react-helmet";
import Foo from "../../test.d";

import "./Sport.scss";
import { useTypeSelector } from "../../hooks/useTypeSelector";

const Sport = () => {
  const balance = useTypeSelector(
    (data) => data.accountInfoReducer.balance.balance
  );

  const balanceInit = useTypeSelector(
    (data) => data.accountInfoReducer.balance.init
  );
  const bearerToken = localStorage.getItem("token") || "1234";
  const user = useTypeSelector((data) => data.accountInfoReducer.user);
  useEffect(() => {
    if (balanceInit) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const APIFrame = Foo.APIFrame;
        const options = {
          serverUrl: "https://sport.funexx.com",
          token: bearerToken,
          externalSiteId: "veyj13",
          userId: user.id,
          locale: "en",
          currency: balance.currency,
          theme: "dark",
          dataSource: "tnm",
          desktop: true,
          balance: balance.amount,
          externalCssPath: `${window.location.origin.toString()}/sportobx.css`, 
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          loginHandler: () => {},
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          depositHandler: () => {},
        };

        APIFrame.init(options);

        APIFrame.changeBalance(1000, "RUB");
      }, 2000);
    }
  }, []);
  return (
    <Layout
      containerStyles="is-home-page  "
      navHeader="sports"
      footerNav="sports"
    >
      <div className="layout-content-holder-bc">
        <div>
          <div id="sport-container" className=" custom-container"></div>
        </div>
        <Helmet>
          <script
            type="text/javascript"
            src="https://sport.funexx.com/js/widget-integration-new.js"
          />
        </Helmet>
      </div>
    </Layout>
  );
};

export { Sport };
