import React from "react";

import "./CaruselCart.scss";

type cartCaruselInfo = {
  img: string;
  name: string;
};

interface CaruselCart {
  cartCaruselInfo: cartCaruselInfo;
}

const CaruselCart = ({ cartCaruselInfo }: CaruselCart) => {
  return (
    <div className="carousel-slide " style={{ width: "100%", left: "0%" }}>
      <div className="sdr-item-holder-bc">
        <a className="sdr-item-bc">
          <img
            src={cartCaruselInfo.img}
            loading="lazy"
            className="sdr-image-bc"
            alt=""
            title={cartCaruselInfo.name}
          />
        </a>
      </div>
    </div>
  );
};

export { CaruselCart };
