import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Carusel.scss";
import "swiper/css";
import { CaruselCart } from "./caruselCart/сaruselCart";

interface ICarusel {
  mockCarusel: { img: string; name: string }[]
}

const Carusel = ({ mockCarusel }: ICarusel) => {
  const [indexCarusel, setIndexCarusel] = useState(1);
  return (
    <div
      className={`hm-row-bc has-slider custom-slider-container `}
      style={{ gridTemplateColumns: "12fr" }}
    >
      <div className="slider-bc  ">
        <div className="carousel">
          <div className="carousel-count custom-counter">
            {indexCarusel} / {mockCarusel.length}
          </div>
          <div className="carousel-viewport">
            <Swiper
              className="carousel-container"
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={(e) => setIndexCarusel(e.activeIndex + 1)}
            >
              {mockCarusel.map((item) => {
                return (
                  <SwiperSlide key={item.img}>
                    <CaruselCart cartCaruselInfo={item} key={item.img} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Carusel };
